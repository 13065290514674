/* You can add global styles to this file, and also import other style files */
/* regular style toast */
/* @import '~ngx-toastr/toastr'; */
/* .int-casino-slider { 
    width: 500px;
} */
@import '~swiper/swiper-bundle.css';
/*.bet-table .theme-loader {display: inline-block !important;padding-top: 25%;}

.bet-table .theme-loader #spinner {
    width: 30px;
    height: 30px;
}*/
/* .toast-container .ngx-toastr {
    padding: 5px 5px 5px 34px !important;
    background-size: 15px !important;
}
.toast-close-button {
    right: 3px !important;
    top: -3px !important;
} */
.desktop-television .video,
.mobile-television .video{
    position: relative;
    height: 300px;
}
.desktop-television .player
.mobile-television .player,
.player{
    position: absolute;
    bottom: 5px;
}
.icasino_ul_tabs .nav-tabs .nav-item a {
    font-size: 12px;
    text-transform: capitalize;
}
.icasino_ul_tabs .nav-tabs .nav-item a img{
    display: block;
    width: 20px;
    margin: 0 auto;
}
.deposit_withdrawal iframe {
    width: 100%;
    height: calc(100vh - 100px);
    border: none;
    background: none;
}
button.casino-back{
    background: linear-gradient(to right, #c18100, #00319a, #c18100);
    color: #000;
    border: 1px solid #000;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
}
.deposit_withdrawal
.int-casino-slider .swiper-container {
    /* width: 100%;
    position: relative; */
    overflow: hidden;
    /* list-style: none;
    padding: 0;
    z-index: 1; */
}
tabset.nav-hidden .innertabs {
    display: none;
}
.bonus_lists_tabs ul li a{
    text-transform: capitalize;
    border: 1px solid #c7870c;
    color: #c7870c;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 10px;
}
.bonus_lists_tabs ul li a.active,
.bonus_lists_tabs ul li a:hover{
    background: linear-gradient(to right, #c18100, #00319a, #c18100);
    color: #000;
}
.bonus_lists_tabs .progress-bar-striped{
    background: linear-gradient(to right, #c18100, #f5b942, #c18100);
}
.bonus_lists_tabs .progress-label {
    text-align: center;
    font-size: 13px;
}

.bonus_lists .panel.card.panel-default {
    padding: 0;
    margin: 0;
    background: none;
    box-shadow: none;
}
.bonus_lists .panel.card.panel-default .panel-heading {
    padding: 3px;
    border: none;
    background: #f1f1f1;
}
.bonus_lists .panel.card.panel-default .panel-heading button {
    padding: 0 5px;
    text-decoration: none;
    color: #000;
    font-size: 13px;
}
.bonus_lists .panel.card.panel-default .panel-heading button:focus{
    box-shadow: none;
    outline: none;
}
.bonus_lists [aria-expanded="false"] button:after{
    content: "\ea5b";
    font: normal normal normal 24px/1 "IcoFont";
    top: 2px;
    position: relative;
    font-size: 14px;
}
.bonus_lists [aria-expanded="true"] button:after{
    content: "\ea5e";
    font: normal normal normal 24px/1 "IcoFont";
    top: 2px;
    position: relative;
    font-size: 14px;
}
.bonus_lists .panel-body{
    padding: 5px;
    color: #000;
    font-size: 13px;
    text-align: justify;
    background: #f1f1f1;
    border-radius: 5px;
}
.dn { display: none; }
.text-green { color: green; }
.text-red { color: red; }

.casino__details iframe{
    height: calc(100vh - 50px);
    width: 100%;
    border: none;
}
.page-item.active .page-link {
  background-color: var(--theme-primary) !important;
  border-color: var(--theme-primary) !important;
}
typeahead-container.dropdown {
    width: 100% !important;
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
}

typeahead-container.dropdown button {
    display: block;
    white-space: normal;
    padding: 5px !important;
    font-size: 13px;
}
typeahead-container{
    width: 100% !important;
    left: 0 !important;
    transform: none !important;
    top: 34px !important;
    border-radius: 0 !important;
    padding: 0 !important;
    height: 200px !important;
}
typeahead-container button.active{
    background: none !important;
}
typeahead-container button{
    border-bottom: 1px solid #eaeaea !important;
    padding: 15px !important;
    background: none !important;
    color: #000 !important;
    text-align: left !important;
    line-height: normal !important;
    border-right: none !important;
    border-left: none !important;
    border-radius: 0 !important;
}
typeahead-container button a{
    color: #000 !important;
}
typeahead-container button:last-child{
    border-bottom: none !important;
}
typeahead-container button:hover{
    box-shadow: none !important;
    border-color: #ddd !important;
    background: #eee !important;
}

.cs_divs_position typeahead-container {
    width: 30% !important;
    top: 50px !important;
    height: 150px !important;
    overflow-y: auto !important;
    border-radius: 4px !important;
}
.cs_divs_position typeahead-container button {
    padding: 8px 11px !important;
    border: none !important;
    white-space: break-spaces;
}
.cs_divs_position typeahead-container button h5 {
    font-size: 15px;
    font-weight: 300;
    color: #e6be41;
    margin: 0;
}

.btn-custom-loader-d, .btn-custom-loader-m { position: relative; }

.btn-custom-loader-m i { top: 7px; right: 0; left: 0; position: absolute; }
.btn-custom-loader-d i { top: 10px; right: 0; left: 0; position: absolute; }
.LiveStream-video-col .bgimage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.competition-head h3 {
    width: 100%;
    display: block;
    background: var(--theme2-bg);
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    padding: 2px 14px;
    color: var(--secondary-color);
}
.forceChangePasssword form.cp-form {
    flex: inherit;
    max-width: 100%;
    border: none;
    margin: 22% 0;
}
iframe.LiveStream-video-col {
    width: 100%;
    height: 212px;
    border: none;
}
@media screen and (max-width: 767px) {
    .full-overlay.mobile_betslip .theme-loader{
        z-index: 9;
        top: -100%;
        height: 100rem;
        left: 0;
        display: inherit;
        padding-top: 100%;
    }
    /*.int-casino-slider { 
        width: 100% !important;
    }
    .int-casino-slider img {
        width: 90%;
    }*/
    iframe.LiveStream-video-col {
        width: 100%;
        height: 212px;
        border: none;
    }
    typeahead-container{
        width: 100% !important;
        left: 0 !important;
        transform: none !important;
        top: 56px !important;
        border-radius: 0 !important;
        padding: 0 !important;
        height: 300px !important;
    }
    typeahead-container button.active{
        background: none !important;
    }
    typeahead-container button{
        border-bottom: 1px solid #eaeaea !important;
        padding: 15px !important;
    }
    typeahead-container button a{
        color: #000 !important;
    }
}